
import { defineComponent } from "vue";
import store from "@/store";
import { mapState } from "vuex";
import Footer from "@/components/Footer.vue";
export default defineComponent({
  created() {
    let user = store.getters["session/getUser"];
    if (!user) {
      (this.text = "This page cannot be accessed until you have logged in"),
        (this.subtext =
          "Please contact your System Administrator for the proper URL.");
    }
  },
  components: {
    Footer,
  },
  computed: {
    ...mapState(["theme", "session"]),
  },
  data() {
    return {
      text: "PAGE NOT FOUND",
      subtext: "OOPS! Looks like you were left on read.",
    };
  },
});
